<template>
	<div class="page-sub-box">
		<div class="locker_setting_list sub_new_style01 sub_ui_box1">
			<Search
				codeKey="search_cdr_type"
				:searchObj="searchType.obj"
				:useKeywordType="true"
				@change-search-type="$_changeSearchType"
				@onSearchClick="$_searchData($event, 'search_cdr_type')"
			>
			</Search>
		</div>

		<esp-dx-data-grid :data-grid="dataGrid" ref="cdrGrid" @init-new-row="onInitNewRow"></esp-dx-data-grid>
	</div>
</template>
<script>
import Search from '@/components/common/search.vue';
import { DxSwitch } from 'devextreme-vue/switch';
import {isSuccess} from "@/plugins/common-lib";
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

export default {
	components: {
    EspDxDataGrid,
		Search,
	},
	watch: {},
	data() {
		return {
			config: {
				pageSetting: {
					//pageData pageSetting 관련
					config: {},
				},
				gradeList: null,
			},
			stylingMode: 'outlined', //outlined, underlined, filled
			searchType: {
				obj: {},
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {},
				paramsData: null,
			},
			dataGrid: {
        callApi: 'CALL_EUC_API',
				excel: {
					title: 'CDR 목록'
				},
				refName: 'cdrGrid',
				allowColumnResizing: true, //컬럼 사이즈 허용
				showBorders: false, //border 유무
				showColumnHeaders: true, //컬럼 헤더 유무
				showColumnLines: false, //컬럼 세로선 유무
				showRowLines: true, //컬럼 가로선 유무
				rowAlternationEnabled: false,
				dataSource: [],
				// width:'200',     // 주석처리시 100%
				height: '650',    // 주석처리시 100%
				apiActionNm: {
					update: 'EUC_DEVICE_CDR_UPDATE',
					delete: 'EUC_DEVICE_CDR_DELETE',
          loading: true
				},
				showActionButtons: {
					save: true,
					delete: true,
					excel: true,
				},
				customEvent: {
					initNewRow: true,
				},
				isDuplicateConfigKey: false, //설정키 중복 체크
				grouping: {
					contextMenuEnabled: false,
					autoExpandAll: false,
					allowCollapsing: true,
					expandMode: 'rowClick', // rowClick or buttonClick
				},
				groupPanel: {
					visible: false,
				},
				columnChooser: {
					enabled: false, // 컬럼 Chooser 버튼 사용유무
				},
				loadPanel: {
					enabled: true, // 로딩바 표시 유무
				},
				sorting: {
					mode: 'multiple', // single multiple
				},
				remoteOperations: {
					// 서버사이드 여부
					filtering: false,
					sorting: false,
					grouping: false,
					paging: false,
				},
				paging: {
					// scrolling 미사용시만 적용됨
					enabled: true,
					pageSize: 10,
					pageIndex: 0, // 시작페이지
				},
				pager: {
					visible: true, //페이저 표시 여부
					showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
					allowedPageSizes: [],
					displayMode: 'compact', //표시 모드 : ['full', 'compact']
					showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
					showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
				},
				filterRow: {
					visible: true,
				},
				headerFilter: {
					visible: false,
				},
				editing: {
					allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
					allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
					allowDeleting: false,
					mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
					startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
					selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
				},
				selecting: {
					mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
					selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
					showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
				},
				columns: [
					{
						dataField: 'id',
						alignment: 'center',
						visible: false,
					},
					{
						caption: '세무소명',
						dataField: 'cdrNm',
						width: 250,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						requiredRule: {
							message: '필수 항목입니다.'
						},
					},
					{
						caption: '코드값',
						dataField: 'groupCd',
						width: 200,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						requiredRule: {
							message: '필수 항목입니다.'
						},
					},
					{
						caption: 'CDR 등록 값',
						dataField: 'cdrCd',
						width: 200,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
						requiredRule: {
							message: '필수 항목입니다.'
						},
					},
					{
						caption: '설명',
						dataField: 'description',
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						allowGrouping: false,
					},
					{
						caption: '순서',
						dataField: 'cdrOrd',
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: true,
						sortOrder: 'asc',
						allowHeaderFiltering: false,
						allowGrouping: false,
					},
					{
						caption: '사용 여부',
						dataField: 'viewCd',
						width: 150,
						height: 40,
						alignment: 'center', // left center right
						visible: true,
						allowEditing: false,
						sortOrder: 'none',  // acs desc none
						allowHeaderFiltering: false,
						cellTemplate: (container, options) => {
							const switchBtn = new DxSwitch({
								propsData: {
									value: options.value === this.$_getUsedCode.id,
									onValueChanged: () => {
										this.onChangedViewCd(options.data);
									},
								},
							});
							switchBtn.$mount();
							container.append(switchBtn.$el);
						},
					},
					{
						caption: '등록일',
						dataField: 'regDt',
						width: 200,
						height: 40,
						alignment: 'center',
						visible: true,
						allowEditing: false,
						sortOrder: 'none',
						allowHeaderFiltering: false,
						calculateCellValue: this.formatDt,
					},
				],
			},
		};
	},
	computed: {
		/** @description: pageData -> pageSetting apiActionNm 가져오기 */
		getApiActionNm() {
			return this.config.pageSetting.config?.apiActionNm || null;
		},
	},
	methods: {
		async onChangedViewCd(data) {
			let viewCd;
			if (data.viewCd === this.$_getUsedCode.id) {
				//사용이면
				viewCd = this.$_getUnUsedCode.id; //미사용으로 스위치
			} else {
				//미사용이면
				viewCd = this.$_getUsedCode.id; //사용으로 스위치
			}
			if(data.id) {
				const payload = {
					actionname: 'EUC_DEVICE_CDR_UPDATE',
					data: [{
            id: data.id,
            viewCd
          }],
					loading: true,
				}

				const res = await this.CALL_EUC_API(payload);
				if(isSuccess(res)) {
					this.$_Toast(this.$_msgContents('CMN_SUC_UPDATE'));
					this.$refs.cdrGrid.getGridInstance.refresh();
				} else {
					this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			} else {
				data.viewCd = viewCd;
			}
		},
		/** @description : 데이터 추가 시 기본 값 적용하기 위함 */
		onInitNewRow(e) {
			e.data.viewCd = this.$_getUsedCode.id;
		},
		async selectDataList(sort = '-regDt') {
			this.dataGrid.dataSource = new CustomStore({
				key: 'id',
				load: async (loadOptions) => {
					let params = this.$_getDxDataGridParam(loadOptions);
					if (!params.sort) {
						params.sort = sort;
					}

					params = { ...params, ...this.searchType.paramsData };

					const payload = {
						actionname: 'EUC_DEVICE_CDR_LIST_ALL',
						data: params,
						loading: false,
					};

          const rtnData = {
            data: [],
            totalCount: 0,
          };

          const res = await this.CALL_EUC_API(payload);
          if(isSuccess(res)) {
            rtnData.data = res.data.data;
            rtnData.totalCount = res.data.header.totalCount;
          }
          return rtnData;
				}
			});
		},
		/** @description : 날짜 형식 변환 메서드 */
		formatDt(rowData) {
			if(rowData?.regDt) {
				return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
			}
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			this.$_setPageSettingConfig();
		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountData() {
			this.searchType.paramsData = null;
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
};
</script>
